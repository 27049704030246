import md5 from 'md5';
import { SiteIdent } from '~/@types/siteIdent';

//submit in footer and CMS block
export function onNewsletterSubmit(
  formAction: string,
  form: any,
  blockType: string,
) {
  (document.activeElement as HTMLElement)?.blur();
  const actionUrlSplitted = formAction.split('subscribe');
  let url = actionUrlSplitted[0] + 'subscribe/post-json' + actionUrlSplitted[1];
  url +=
    '&' +
    new URLSearchParams(form.value).toString() +
    '&SIGNUP=Homepage-Footer' +
    `&c=window.mcNewsletter${blockType}.validateResponse`;

  addScriptForNewsletterSubmit(url);
}

//submit in checkout
export function onCheckoutNewsletterSubmit(actionUrl: string, email: string) {
  (document.activeElement as HTMLElement)?.blur();
  const actionUrlSplitted = actionUrl.split('subscribe');

  let url = actionUrlSplitted[0] + 'subscribe/post-json' + actionUrlSplitted[1];
  url +=
    `&EMAIL=${encodeURIComponent(email)}&SIGNUP=Checkout` +
    `&c=window.mcNewsletterCheckout.validateResponse`;

  addScriptForNewsletterSubmit(url);
}

function addScriptForNewsletterSubmit(url: string) {
  // Create script with url and callback (if specified)
  const script = window.document.createElement('script');
  script.src = url;

  // Insert script tag into the DOM (append to <head>)
  const ref = window.document.getElementsByTagName('script')[0];
  ref.parentNode.insertBefore(script, ref);

  // After the script is loaded (and executed), remove it
  script.onload = function () {
    ref.parentNode.removeChild(script);
  };
}

/**
 *
 * @param email
 * the email from the order
 * @returns true or false
 * when email is already subscribed
 */
export async function checkSubscribedWithMail(email: string) {
  const hashedMail = md5(email.toLowerCase());

  const result = await useFetch(
    `/api/${useSiteIdent()}/mailchimp/member/${hashedMail}`,
  );

  return result;
}

export function getActionUrlBySiteIdent(site: SiteIdent) {
  const isProd = useRuntimeConfig().public.ENVIRONMENT_NAME === 'Prod';
  switch (site) {
    case SiteIdent.miniluDe:
      return isProd
        ? 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=7689066d63'
        : 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=3d64db6e00';
    case SiteIdent.miniluAt:
      return isProd
        ? 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=3f4353694d'
        : 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=8416c32a75';
    case SiteIdent.miniluNl:
      return isProd
        ? 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=d5d1432ed3'
        : 'https://minilu.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=5c1ce6ae64';
    case SiteIdent.default:
    default:
      return isProd
        ? 'https://vanderven.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=58973d68f1'
        : 'https://vanderven.us20.list-manage.com/subscribe?u=a8780ef3529534dbe1b892a2a&id=1739cb719c';
  }
}
